var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-page"},[_c('div',{staticClass:"video-banner"}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"content-wrap"},[_vm._m(0),_c('div',{staticClass:"card card-top"},[_c('div',{staticClass:"video-wrapper",attrs:{"id":"container"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.info.vid),expression:"info.vid"}],attrs:{"id":"vidro-content"}})]),_c('div',{staticClass:"card-body d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.info.title))]),_c('i',{class:[
              'iconfont',
              _vm.info.is_favourite == 1
                ? 'icon-favourite_selected'
                : 'icon-favourite_normal',
            ],on:{"click":_vm.toFavourite}})])]),_c('div',{staticClass:"recommend-title"},[_vm._v("推荐短视频")]),_c('div',{staticClass:"video-list"},_vm._l((_vm.recommendList),function(item){return _c('div',{key:item.video_id,staticClass:"card",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('div',{staticClass:"card-img-top"},[_c('img',{attrs:{"src":item.cover}})]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(item.title))])])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/home"}},[_vm._v("首页")])]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/videolist"}},[_vm._v(" 安全警示短视频学习")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" 安全警示短视频学习详情 ")])])])
}]

export { render, staticRenderFns }