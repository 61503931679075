<template>
  <div class="video-page">
    <div class="video-banner"></div>
    <div class="container">
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item">
              <a href="/videolist"> 安全警示短视频学习</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              安全警示短视频学习详情
            </li>
          </ol>
        </nav>
        <div class="card card-top">
          <div id="container" class="video-wrapper">
            <div id="vidro-content" v-show="info.vid"></div>
          </div>
          <div
            class="card-body d-flex justify-content-between align-items-center"
          >
            <div class="card-title">{{ info.title }}</div>
            <i
              :class="[
                'iconfont',
                info.is_favourite == 1
                  ? 'icon-favourite_selected'
                  : 'icon-favourite_normal',
              ]"
              @click="toFavourite"
            ></i>
          </div>
        </div>
        <div class="recommend-title">推荐短视频</div>
        <div class="video-list">
          <div
            class="card"
            v-for="item in recommendList"
            :key="item.video_id"
            @click="toDetail(item)"
          >
            <div class="card-img-top">
              <img :src="item.cover" />
            </div>
            <div class="card-body">
              <div class="card-title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  videoDetail,
  recommendVideoList,
  videoFavourite,
} from "@/network/API.js";
import "../../utils/txplayer.js";
export default {
  data() {
    return {
      recommendList: [],
      videoId: "",
      info: {},
      player: null,
    };
  },
  watch: {
    $route(to, from) {
      if (to.params.id) {
        this.videoId = to.params.id;
        this.getDetail();
      }
    },
  },
  async created() {
    if (this.$route.params.id) {
      this.videoId = this.$route.params.id;
    }
    this.getDetail();
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
  methods: {
    async getDetail() {
      let data = await videoDetail(this.videoId);
      this.info = data;
      this.recommendList = await recommendVideoList(data.video_id);

      if (this.info.vid) {
        this.playerInit(this.info.vid);
      }
    },
    playerInit(vid) {
      this.player = new Txplayer({
        containerId: "container",
        vid: vid,
        width: "100%",
        height: screen.availHeight / 2,
        autoplay: true,
      });
    },
    async toFavourite() {
      let res = await videoFavourite({
        id: this.info.video_id,
      });
      if (res.is_favourite == 1) {
        this.$toast("收藏成功");
      } else {
        this.$toast("取消收藏");
      }
      this.info.is_favourite = res.is_favourite;
    },
    toDetail(item) {
      this.$router.push({
        name: "VideoDetail",
        params: {
          id: item.video_id,
        },
      });
    },
  },
};
</script>
<style scoped>
.video-banner {
  width: 100%;
  height: 300px;
  background: url(../../assets/safety/banner-video.jpg) center center no-repeat;
  margin-bottom: -32px;
}
.feature {
  display: flex;
  justify-content: space-around;
}
.pk-box {
  width: 50%;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 4px solid #fff;
  border-radius: 20px 20px 0 0;
  cursor: pointer;
  background: linear-gradient(
    0deg,
    #ffffff 0%,
    #feeeda 0%,
    #fef6ec 0%,
    #ffffff 100%
  );
}
.pk-box:hover span {
  color: #ff6d00;
}
.pk-box.active {
  border-bottom: 4px solid #ff6d00;
}
.pk-box.active span {
  color: #ff6d00;
}
.pk-box img {
  width: 60px;
  margin-right: 10px;
}

.pk-box span {
  font-size: 24px;
  font-weight: 500;
  color: #666666;
  line-height: 36px;
}

/* 选项卡 */

.nav-pills.nav-orange .nav-link {
  margin: 0;
  margin-right: 20px;
  padding-left: 30px;
  padding-right: 30px;
  color: #333;
}

.nav-pills.nav-orange .nav-link:hover {
  color: #ff6d00;
}

.nav-pills.nav-orange .nav-link.active {
  background: linear-gradient(107deg, #ffd993 0%, #ff6d00 99%);
  color: #fff;
  border: none;
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -15px 20px -15px;
}
.video-list .card {
  margin: 15px;
  width: calc((100% - 90px) / 3);
  border: none;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 10px;
  transition: all linear 0.2s;
}
.video-list .card:hover {
  transform: translateY(-5px);
}
nav {
  position: relative;
}
.search-box {
  top: 10px;
}

.recommend-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 40px;
}
.card-top .iconfont {
  font-size: 36px;
  color: #ff6d00;
}
.card-top .card-title {
  font-size: 21px;
}
.card-top .video {
  width: 1000px;
}
</style>
